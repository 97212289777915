<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-icon>fas fa-filter</v-icon>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <!--#region OrderTypeof  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.OrderTypeof.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="orderTypeOfValues"
                                    v-model="val.OrderTypeof.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.OrderTypeof.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    <!--#region IsSignerControl  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.IsSignerControl.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="isSignerControlValues"
                                    v-model="val.IsSignerControl.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.IsSignerControl.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->                 

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { mapActions } from 'vuex';

export default {
    name: "MyTaskExFilter",
    props:
    {
        value:
        {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),

            orderTypeOfValues: [
                { id: "Avrora.Objects.Modules.Docflow.DocflowObjects.Resolution", Value: i18n.t("Резолюция") },
                { id: "Avrora.Objects.Modules.Docflow.DocflowObjects.ActItem", Value: i18n.t("Пункт_ОРД") },
                { id: "Avrora.Objects.Modules.Docflow.DocflowObjects.Order", Value: i18n.t("Личное_поручение") }
            ],
            isSignerControlValues: [
                { id: true, Value: i18n.t("Да") },
                { id: false, Value: i18n.t("Нет") }
            ]
        }
    },
    methods: {
        ...mapActions('references', ['getReference', 'getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        async initialize() {
            
        },
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);

            this.setOverlayVisible({ visible: true });
            this.initialize();
            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);                                
            //this.$notify.success("Фильтр_применен");
            this.resolve();
        }
    }
}
</script>
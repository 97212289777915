<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">
                <v-icon
                    v-if="showExtendedFilter"
                    class="extend-filter"
                    v-tooltip.left-center="$t('Расширенный_поиск')"
                    left
                    @click="openExtendedFilterDialog"
                >
                    mdi-filter
                </v-icon>

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        >
            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            /> 

        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            @change_loaddata_status="onLoadDataStatusChanged"
            ref="currentComponent"
        />

        <MyTaskExFilter 
            v-if="collection === 'MyCreatedTask' || collection === 'MyExecutionTask'"
            v-model="extendedTableFilter" 
            ref="MyTaskExFilterRef" 
        />

        <MyControlTaskExFilter 
            v-if="collection === 'MyControlTask'"
            v-model="extendedTableFilter" 
            ref="MyControlTaskExFilterRef" 
        />

    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import MyControlTask from './lists/MyControlTask.vue';
import MyCreatedTask from './lists/MyCreatedTask.vue';
import MyExecutionTask from './lists/MyExecutionTask.vue';
import ExtendedFilterPanel from '@/components/ExtendedFilterPanel.vue';
import MyTaskExFilter from '@/components/dialogs/extended-filters/tasks/MyTasks.vue';
import MyControlTaskExFilter from '@/components/dialogs/extended-filters/tasks/MyControlTasks.vue';

export default {
    name: "TasksProxy",
    components: {
        Toolbar,
        FilterPanel,
        MyControlTask,
        MyCreatedTask,
        MyExecutionTask,
        ExtendedFilterPanel,
        MyTaskExFilter,
        MyControlTaskExFilter
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
            urls: [],
            dataloading:false,
            filterloading:false,
            collectionloading:false,
        }
    },
    computed: {
        filterloadingcomp:{
            get: function() { return this.filterloading; },
            set: function(newValue) { this.filterloading = newValue; this.refreshOverlay(); }
        },
        collectionloadingcomp:{
            get: function() { return this.collectionloading; },
            set: function(newValue) { this.collectionloading = newValue; this.refreshOverlay(); }
        },
        collection: {
            get: function() {
                return this.$store.getters['tasks/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('tasks/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                var statuses = this.$store.getters['tasks/getStatus'];
                if (!statuses) return "";
                
                var result =  statuses.find(x => x.collection == this.collection)?.value ?? "";
                return result;
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('tasks/PUSH_STATUS', { collection: this.collection, value: newValue } );

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                var searches = this.$store.getters['tasks/getSearches'];

                if (!searches) return "";
                
                return searches.find(x => x.collection == this.collection)?.value ?? "";
            },
            set: _.debounce(function(v) {
                
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('tasks/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        extendedTableFilter: {
                get: function() {
                return this.$store.getters['tasks/getExtendedFilter'];
            },
            set: function(newValue) {    
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }

                if (newValue)
                    this.$store.commit('tasks/PUSH_EXTENDED_FILTER', { collection: this.collection, value: newValue } );
            }
        },
        showExtendedFilter(){
            return process.env.VUE_APP_CONFIGURATION != 'C5';
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onLoadDataStatusChanged(status){
            this.dataloading = status;
            this.refreshOverlay();    
        },
        refreshOverlay(){
            this.setOverlayVisible({ visible: this.dataloading || this.filterloading  || this.collectionloading});
        },
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadFilters() {
            this.filterloadingcomp = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let filterResponse = await httpAPI({
                url: `/api/tasks/filter?collection=${this.collection}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['auth/getUserInfo'])?.permissions ?? [];

                filterResponse.data.payload.filter.items = 
                    filterResponse.data.payload.filter.items
                        .filter(i => process.env.VUE_APP_CONFIGURATION == 'C5' ? i.value != 'MyControlTask' : true )
                        .filter(i => i.requires.length === 0 || this.$_.intersection(i.requires, permissions).length > 0);

                this.filterDataSource = filterResponse.data.payload;
            }
            this.filterloadingcomp = false;
        },
        async openExtendedFilterDialog() {            
            try
            {
                switch(this.collection)
                {
                    case "MyCreatedTask":
                    case "MyExecutionTask":
                        await this.$refs.MyTaskExFilterRef.open();
                        break;

                    case "MyControlTask":
                        await this.$refs.MyControlTaskExFilterRef.open();
                        break;

                    default: break;
                }
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        },
        async Export() {
            let exportURI = null;
            let filter = this.$refs.currentComponent.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;
            filter.collection = this.collection;
            exportURI = `api/download/export?param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;

            if (process.env.NODE_ENV === "production")
                exportURI += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
            
            let itemName = process.env.VUE_APP_CONFIGURATION != 'C5' ? "задач" : "нарядов";
            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка ${itemName} от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'Xls' });
            /*this.$store.dispatch('setOverlayVisible', { visible: true, text: 'Отчет_формируется...' });

            if (process.env.NODE_ENV === "production")
                exportURI += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                
            let response = await httpAPI({
                url: exportURI,
                method: 'GET',
                responseType: 'blob',
            });

            this.$store.dispatch('setOverlayVisible', { visible: false });

            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.urls.push(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Экспорт списка задач от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}.xls` );
                document.body.appendChild(link);
                link.click();
            }*/
        }
    },
    watch: {
        collection() {
            this.loadFilters();
        }
    },
    async created () {
        this.collectionloadingcomp = true;

        let response = await httpAPI({
            url: `api/actions/collection?type=Orders.Order`,
            method: 'GET'
        });

        if (response) {
            await this.loadFilters();
            this.dataSource = response.data.payload;
        }
        
        this.collectionloadingcomp = false;
    },
    beforeDestroy() {
        this.urls.forEach(url => URL.revokeObjectURL(url));
    }
}
</script>